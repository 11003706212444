var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
          ? _c("span", { staticClass: "secondary--text font-weight-bold" }, [
              _vm._v("Course Configuration"),
            ])
          : _vm.currentSpaceType === _vm.spaceTypes.RESEARCH_SPACE
          ? _c("span", { staticClass: "secondary--text font-weight-bold" }, [
              _vm._v("Project Configuration"),
            ])
          : _vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE
          ? _c("span", { staticClass: "secondary--text font-weight-bold" }, [
              _vm._v("Dataset Configuration"),
            ])
          : _vm._e(),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _vm.isSpaceAdmin
                ? _c("v-tab", [_vm._v("General Settings")])
                : _vm._e(),
              _c("v-tab", [_vm._v("Secrets")]),
              _c("v-tab", [_vm._v("Large file storage")]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _vm.isSpaceAdmin
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c("v-card-title", [
                            _c(
                              "span",
                              {
                                staticClass: "secondary--text font-weight-bold",
                              },
                              [_vm._v("General settings")]
                            ),
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("h3", [_vm._v("Integrations")]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        lg: "4",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-1",
                                          attrs: { flat: "", elevation: "3" },
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            {
                                              attrs: {
                                                "three-line": "",
                                                color: "transparent",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-avatar",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "primary lighten-1",
                                                          attrs: { dark: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.isDropboxLinked
                                                                ? "check_circle"
                                                                : "circle"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v("Dropbox"),
                                                      ]),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " Each member of a space needs to enable Dropbox synchronization separately for their own account. "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              !_vm.isDropboxLinked
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        color: "primary",
                                                        to: {
                                                          name: "user-dropbox",
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Enable")]
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        color: "secondary",
                                                        disabled: "",
                                                      },
                                                    },
                                                    [_vm._v("Enabled")]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(_vm.featureGroups, function (name, id) {
                                return [
                                  _c(
                                    "v-row",
                                    { key: id, staticClass: "mt-4" },
                                    [
                                      _c("v-col", [
                                        _c("h3", [_vm._v(_vm._s(name))]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { key: name },
                                    _vm._l(
                                      _vm.featuresByGroup(id),
                                      function (feature, index) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: index,
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              lg: "4",
                                              xl: "3",
                                            },
                                          },
                                          [
                                            _c("org-feature-status", {
                                              attrs: {
                                                feature: feature,
                                                enableOnly: true,
                                                level: "space",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              }),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("h3", [_vm._v("Resource mapping")]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "4",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-1",
                                          attrs: { flat: "", elevation: "3" },
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            {
                                              attrs: {
                                                "three-line": "",
                                                color: "transparent",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Credit-based services"
                                                        ),
                                                      ]),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " All credit-based services are drawing upon this resource pool when they are utilized in this space. "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "py-0" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  items:
                                                    _vm.managerResourcePools,
                                                  disabled:
                                                    _vm.fetchingAvailableResourcePools,
                                                  loading:
                                                    _vm.fetchingAvailableResourcePools,
                                                  "item-text": "long_id",
                                                  "item-value": "rpid",
                                                  label: "Resource pool",
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.resourcePoolIdCredit,
                                                  callback: function ($$v) {
                                                    _vm.resourcePoolIdCredit =
                                                      $$v
                                                  },
                                                  expression:
                                                    "resourcePoolIdCredit",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _vm.managerResourcePools.length >
                                              0
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        text: "",
                                                        disabled:
                                                          _vm.loading ||
                                                          _vm.fetchingAvailableResourcePools,
                                                        loading:
                                                          _vm.loading ||
                                                          _vm.fetchingAvailableResourcePools,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.updateResourcePoolForCredit,
                                                      },
                                                    },
                                                    [_vm._v(" save ")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "4",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-1",
                                          attrs: { flat: "", elevation: "3" },
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            {
                                              attrs: {
                                                "three-line": "",
                                                color: "transparent",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Plan-based services"
                                                        ),
                                                      ]),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " All plan-based services are drawing upon this resource pool when they are utilized in this space. "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "py-0" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  items:
                                                    _vm.managerResourcePools,
                                                  disabled:
                                                    _vm.fetchingAvailableResourcePools,
                                                  loading:
                                                    _vm.fetchingAvailableResourcePools,
                                                  "item-text": "long_id",
                                                  "item-value": "rpid",
                                                  label: "Resource pool",
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.resourcePoolIdSubscription,
                                                  callback: function ($$v) {
                                                    _vm.resourcePoolIdSubscription =
                                                      $$v
                                                  },
                                                  expression:
                                                    "resourcePoolIdSubscription",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _vm.managerResourcePools.length >
                                              0
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        text: "",
                                                        disabled:
                                                          _vm.loading ||
                                                          _vm.fetchingAvailableResourcePools,
                                                        loading:
                                                          _vm.loading ||
                                                          _vm.fetchingAvailableResourcePools,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.updateResourcePoolForSubscription,
                                                      },
                                                    },
                                                    [_vm._v(" save ")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "4",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-1",
                                          attrs: { flat: "", elevation: "3" },
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            {
                                              attrs: {
                                                "three-line": "",
                                                color: "transparent",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Large File Storage visibility"
                                                        ),
                                                      ]),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " Only LFS objects in this resource pool are visible and can be attached to this space. "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "py-0" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  items:
                                                    _vm.managerResourcePools,
                                                  disabled:
                                                    _vm.fetchingAvailableResourcePools,
                                                  loading:
                                                    _vm.fetchingAvailableResourcePools,
                                                  "item-text": "long_id",
                                                  "item-value": "rpid",
                                                  label: "Resource pool",
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value: _vm.resourcePoolIdLFS,
                                                  callback: function ($$v) {
                                                    _vm.resourcePoolIdLFS = $$v
                                                  },
                                                  expression:
                                                    "resourcePoolIdLFS",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _vm.managerResourcePools.length >
                                              0
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        text: "",
                                                        disabled:
                                                          _vm.loading ||
                                                          _vm.fetchingAvailableResourcePools,
                                                        loading:
                                                          _vm.loading ||
                                                          _vm.fetchingAvailableResourcePools,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.updateResourcePoolForLFS,
                                                      },
                                                    },
                                                    [_vm._v(" save ")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab-item",
                [_c("the-secrets-listing", { attrs: { level: 2 } })],
                1
              ),
              _c("v-tab-item", [_c("the-space-LFS")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }